import { initFacebookSdk } from '@/config/facebook_oAuth.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Constants from '../../config/dev.json'

export default {
  name: 'login_signup_social',
  mounted () {
    initFacebookSdk()
  },
  computed: {
    ...mapGetters({
      getUser: 'getUser'
    })

  },
  methods: {
    ...mapActions({
      GET_USER: 'GET_USER',
      LOGIN: 'LOGIN'
    }),
    ...mapMutations([
      'setShowLoader'
    ]),
    loginWithGoogle () {
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          const googleAuthResponse = GoogleUser.getAuthResponse()
          this.loginSocial(GoogleUser.getBasicProfile().zt, googleAuthResponse.id_token, 'GOOGLE')
        })
        .catch(error => {
          // eslint-disable-next-line no-unused-vars
          var c = error
        })
    },
    loginWithFacebook () {
      window.FB.login(response => {
        if (response.authResponse.accessToken) {
          var url = '/me?fields=name,email'
          window.FB.api(url, emailIdresponse => {
            this.loginSocial(emailIdresponse.email, response.authResponse.accessToken, 'FACEBOOK')
          }
          )
        } else {
        }
      }, { scope: 'email' })
    },
    loginWithLinkedIn () {
      this.setShowLoader(true)
      let url
      url =
                Constants.APP_LINKEDIN_BASE_URI +
                '?response_type=code&client_id=' +
                '78y41c9w2yj6df'
      url += '&redirect_uri=' + Constants.APP_LINKEDIN_REDIRECT_URI
      url += '&state=' + 'authLinkedIn'
      url += '&scope=' + encodeURIComponent('r_liteprofile r_emailaddress')
      window.location.href = url
    },
    loginSocial (key, value, loginSource) {
      const credentials = {
        key: key,
        value: value,
        loginSource: loginSource
      }
      this.setShowLoader(true)
      this.LOGIN({
        payload: credentials,
        success: () => {
          this.GET_USER({ success: () => {
            this.$router.push({ name: 'social.list' })
          } })
        }
      })
    }
  }
}
