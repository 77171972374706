import { mapActions, mapGetters, mapMutations } from 'vuex'
import SocialLogin from '@/components/Social/SocialLogin.vue'

export default {
  name: 'SignIn',
  components: {
    SocialLogin
  },
  computed: {
    ...mapGetters({
      getUser: 'getUser',
      getIsAuthenticated: 'getIsAuthenticated',
      getLoginStatus: 'getLoginStatus'
    })

  },
  data () {
    return {
      email: '',
      password: '',
      showBreadCrum: this.getIsAuthenticated
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions({
      GET_USER: 'GET_USER',
      LOGIN: 'LOGIN'
    }),
    ...mapMutations([
      'setErrorStatus'
    ]),
    login () {
      // console.log('lINSIDE ogin:')
      const credentials = {
        key: this.email,
        value: this.password,
        loginSource: 'EMAIL'
      }
      this.LOGIN({
        payload: credentials,
        success: () => {
          this.$router.push({ name: 'social.list' })
        },
        failure: () => {
          // console.log('FAILURE isAuthenticiated: ', this.getIsAuthenticated)
          this.showBreadCrum = false
          if (!this.getIsAuthenticated) {
            // console.log('showBreadCrum:', this.showBreadCrum)
            this.showBreadCrum = true
          }
        }
      })
    },
    handleLogin () {

    }
  }
}
