export function initFacebookSdk () {
  return new Promise(resolve => {
    // wait for facebook sdk to initialize before starting the vue app
    window.fbAsyncInit = function () {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: 490781665333480,
        cookie: true,
        xfbml: true,
        version: 'v8.0'
      })

      // auto authenticate with the api if already logged in with facebook
      // eslint-disable-next-line no-undef
      FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
        } else {
          resolve()
        }
      })
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js; var fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) { return }
      js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  })
}
